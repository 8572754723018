// 
// user.scss
// Use this to write your custom SCSS
//

.dark-mode {
  --ar-primary: #ffb1c3;
  --ar-primary-rgb: 255, 177, 195;
  --ar-link-color: #ffb1c3;
  --ar-link-color-rgb: 255, 177, 195;
  --ar-btn-color: var(--ar-body-bg) !important;
}

.bg-project1 {
  background: #E7E1F2;
}

.bg-project2 {
  background: #F0DBD9;
}
